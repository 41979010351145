var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("Title", { attrs: { name: "普通订单" } }),
      _c("div", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "tbody" },
          _vm._l(_vm.tableData, function (item) {
            return _c("div", { key: item.id, staticClass: "tbody-item" }, [
              _c("div", { staticClass: "tbody-item-head" }, [
                _c("p", { staticStyle: { width: "130px" } }, [
                  _vm._v(_vm._s(item.addTime)),
                ]),
                _c("p", { staticStyle: { width: "280px" } }, [
                  _c("span", [_vm._v("订单号：")]),
                  _vm._v(_vm._s(item.orderSn)),
                ]),
                _vm.customer === "buyder"
                  ? _c("p", [
                      _c("span", [_vm._v("供应商：")]),
                      _vm._v(_vm._s(item.clientName)),
                    ])
                  : _c("p", [
                      _c("span", [_vm._v("买家：")]),
                      _vm._v(_vm._s(item.consignee)),
                    ]),
              ]),
              _c("div", { staticClass: "tr" }, [
                _c(
                  "div",
                  { staticClass: "td goods-box" },
                  _vm._l(item.orderGoods, function (good) {
                    return _c("div", { key: good.id, staticClass: "goods" }, [
                      _c("div", { staticClass: "goods-info" }, [
                        _c("div", { staticClass: "image" }, [
                          _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: good.picUrl, alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("p", { staticClass: "name" }, [
                            _vm._v(_vm._s(good.goodsName)),
                          ]),
                          _c("p", { staticClass: "price" }, [
                            _vm._v("￥" + _vm._s(good.price)),
                          ]),
                        ]),
                      ]),
                      _c("p", { staticClass: "num" }, [
                        _vm._v("x" + _vm._s(good.number)),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "td" }, [
                  _vm._v("￥" + _vm._s(item.orderPrice)),
                ]),
                _c("div", { staticClass: "td status-box" }, [
                  _c(
                    "div",
                    { staticClass: "status" },
                    [
                      item.orderStatus === "DFH"
                        ? _c("p", [_vm._v("待发货")])
                        : item.orderStatus === "DSH"
                        ? _c("p", [_vm._v("待收货")])
                        : item.orderStatus === "DZF"
                        ? _c("p", [_vm._v("待付款")])
                        : item.orderStatus === "DKP"
                        ? _c("p", [_vm._v("待开发票")])
                        : item.orderStatus === "ZFC"
                        ? _c("p", [_vm._v("支付待确认")])
                        : item.orderStatus === "END"
                        ? _c("p", { staticClass: "finished" }, [
                            _vm._v("交易完成"),
                          ])
                        : item.orderStatus === "QXC"
                        ? _c("p", [_vm._v("取消订单审核")])
                        : item.orderStatus === "QXS"
                        ? _c("p", [_vm._v("取消成功")])
                        : item.orderStatus === "DQS"
                        ? _c("p", [_vm._v("合同未签署")])
                        : item.orderStatus === "DFWQ"
                        ? _c("p", [_vm._v("对方还未签署")])
                        : _vm._e(),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "/User/MyOrderInfo",
                              query: {
                                orderid: item.id,
                                customer: _vm.customer,
                              },
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "order-details" }, [
                            _vm._v("订单详情"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm.customer === "buyder"
                    ? _c(
                        "div",
                        { staticClass: "button-box" },
                        [
                          item.orderStatus === "DZF" ||
                          item.orderStatus === "DQS"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitOpr(item, "QX")
                                    },
                                  },
                                },
                                [_vm._v("取消订单")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DSH"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToOrderDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("确认收货")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DZF"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToOrderDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("上传支付凭证")]
                              )
                            : _vm._e(),
                          item.orderStatus === "QXC"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { disabled: "", plain: "" },
                                },
                                [_vm._v("取消审批中")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DQS"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toContractDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("合同签署")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DFWQ"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { disabled: "", plain: "" },
                                },
                                [_vm._v("对方未签")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "button-box" },
                        [
                          item.orderStatus === "DFH"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToOrderDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("上传发货单")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DKP"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToOrderDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("上传开票记录")]
                              )
                            : _vm._e(),
                          item.orderStatus === "ZFC"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToOrderDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("确认收款")]
                              )
                            : item.orderStatus === "QXC"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDialog(item)
                                    },
                                  },
                                },
                                [_vm._v("订单取消审批")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DQS"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toContractDetail(item)
                                    },
                                  },
                                },
                                [_vm._v("合同签署")]
                              )
                            : _vm._e(),
                          item.orderStatus === "DFWQ"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { disabled: "", plain: "" },
                                },
                                [_vm._v("对方未签")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "pager" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.page.total,
              "page-size": _vm.page.pageSize,
              "current-page": _vm.page.currentPage,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单取消",
            visible: _vm.dialogVisible,
            "show-close": true,
            width: "380px",
            top: "34vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit(_vm.currenItem, "QXR")
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit(_vm.currenItem, "QXS")
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "th" }, [_vm._v("产品信息")]),
        _c("div", { staticClass: "th" }, [_vm._v("实付金额")]),
        _c("div", { staticClass: "th" }, [_vm._v("交易状态")]),
        _c("div", { staticClass: "th" }, [_vm._v("交易操作")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }