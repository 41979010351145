import { render, staticRenderFns } from "./MyOrder.vue?vue&type=template&id=44519efb&scoped=true&"
import script from "./MyOrder.vue?vue&type=script&lang=js&"
export * from "./MyOrder.vue?vue&type=script&lang=js&"
import style0 from "./MyOrder.vue?vue&type=style&index=0&id=44519efb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44519efb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44519efb')) {
      api.createRecord('44519efb', component.options)
    } else {
      api.reload('44519efb', component.options)
    }
    module.hot.accept("./MyOrder.vue?vue&type=template&id=44519efb&scoped=true&", function () {
      api.rerender('44519efb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/User/components/order/MyOrder.vue"
export default component.exports