<template>
  <div class="wrap">
    <Title name="普通订单"></Title>

    <div class="table">
      <div class="thead">
        <div class="tr">
          <div class="th">产品信息</div>
          <div class="th">实付金额</div>
          <div class="th">交易状态</div>
          <div class="th">交易操作</div>
        </div>
      </div>
      <div class="tbody">
        <div class="tbody-item" v-for="item in tableData" :key="item.id">
          <div class="tbody-item-head">
            <p style="width: 130px;">{{ item.addTime }}</p>
            <p style="width: 280px;"><span>订单号：</span>{{ item.orderSn }}</p>
            <p v-if="customer === 'buyder'"><span>供应商：</span>{{ item.clientName }}</p>
            <p v-else><span>买家：</span>{{ item.consignee }}</p>
          </div>
          <div class="tr">
            <div class="td goods-box">
              <div class="goods" v-for="good in item.orderGoods" :key="good.id">
                <div class="goods-info">
                  <div class="image">
                    <img :src="good.picUrl" alt="" style="width:100%;height:100%" />
                  </div>
                  <div class="info">
                    <p class="name">{{ good.goodsName }}</p>
                    <p class="price">￥{{ good.price }}</p>
                  </div>
                </div>
                <p class="num">x{{ good.number }}</p>
              </div>
            </div>
            <div class="td">￥{{ item.orderPrice }}</div>
            <div class="td status-box">
              <div class="status">
                <p v-if="item.orderStatus === 'DFH'">待发货</p>
                <p v-else-if="item.orderStatus === 'DSH'">待收货</p>
                <p v-else-if="item.orderStatus === 'DZF'">待付款</p>
                <p v-else-if="item.orderStatus === 'DKP'">待开发票</p>
                <p v-else-if="item.orderStatus === 'ZFC'">支付待确认</p>
                <p v-else-if="item.orderStatus === 'END'" class="finished">交易完成</p>
                <p v-else-if="item.orderStatus === 'QXC'">取消订单审核</p>
                <p v-else-if="item.orderStatus === 'QXS'">取消成功</p>
                <p v-else-if="item.orderStatus === 'DQS'">合同未签署</p>
                <p v-else-if="item.orderStatus === 'DFWQ'">对方还未签署</p>
                <router-link :to="{path: '/User/MyOrderInfo', query: {orderid: item.id, customer: customer}}">
                  <span class="order-details">订单详情</span>
                </router-link>
              </div>
            </div>
            <div class="td">
              <div class="button-box" v-if="customer === 'buyder'">
                <el-button plain v-if="item.orderStatus === 'DZF' || item.orderStatus === 'DQS'" @click="submitOpr(item, 'QX')" style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >取消订单</el-button
                >
                <el-button
                  plain
                  v-if="item.orderStatus === 'DSH'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="jumpToOrderDetail(item)"
                  style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >确认收货</el-button
                >
                <el-button
                  plain
                  v-if="item.orderStatus === 'DZF'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="jumpToOrderDetail(item)"
                  style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >上传支付凭证</el-button
                >
                <el-button disabled plain v-if="item.orderStatus === 'QXC'" style="font-size:12px;padding:6px 15px;margin-right:10px">取消审批中</el-button>

                <el-button plain v-if="item.orderStatus === 'DQS'" @click="toContractDetail(item)" style="font-size:12px;padding:6px 15px;margin-right:10px">合同签署</el-button>
                <el-button disabled plain v-if="item.orderStatus === 'DFWQ'" style="font-size:12px;padding:6px 15px;margin-right:10px">对方未签</el-button>
              </div>
              <div class="button-box" v-else>
                <el-button
                  plain
                  v-if="item.orderStatus === 'DFH'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="jumpToOrderDetail(item)"
                  style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >上传发货单</el-button
                >
                <el-button
                  plain
                  v-if="item.orderStatus === 'DKP'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="jumpToOrderDetail(item)"
                  style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >上传开票记录</el-button
                >
                <el-button
                  plain
                  v-if="item.orderStatus === 'ZFC'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="jumpToOrderDetail(item)"
                  style="font-size:12px;padding:6px 15px;margin-right:10px"
                  >确认收款</el-button
                >
                <el-button plain v-else-if="item.orderStatus === 'QXC'" @click="showDialog(item)" style="font-size:12px;padding:6px 15px;margin-right:10px">订单取消审批</el-button>

                <el-button plain v-if="item.orderStatus === 'DQS'" @click="toContractDetail(item)" style="font-size:12px;padding:6px 15px;margin-right:10px">合同签署</el-button>
                <el-button disabled plain v-if="item.orderStatus === 'DFWQ'" style="font-size:12px;padding:6px 15px;margin-right:10px">对方未签</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页器 -->
    <div class="pager">
      <el-pagination background layout="prev, pager, next" :total="page.total" :page-size="page.pageSize" :current-page="page.currentPage" @current-change="handleCurrentChange"> </el-pagination>
    </div>
    <el-dialog title="订单取消" :visible.sync="dialogVisible" :show-close="true" width="380px" top="34vh">
      <div style="text-align: center">
        <el-button type="warning" round @click="confirmSubmit(currenItem, 'QXR')">拒绝</el-button>
        <el-button type="primary" round @click="confirmSubmit(currenItem, 'QXS')">同意</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Title from '../../component/Title';
import {listOrder, sellderListOrder, oprOrderStatus, changeOrderStatus} from '../../api';
import {permission} from '@/modules/mixin/permission-mixin.js';

export default {
  components: {
    Title
  },
  data() {
    return {
      dialogVisible: false,
      currenItem: {},
      customer: 'buyer', //buyer: 买方, supplier: 供应方
      tableData: [],
      page: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
        pageCount: 0
      }
    };
  },
  mixins: [permission],
  methods: {
    showDialog(item) {
      this.currenItem = item;
      this.dialogVisible = true;
    },
    // 提交交易操作
    submitOpr(item, status) {
      console.log(status);
      const title = status === 'QXS' || status === 'QX' || status === 'QXC' ? '取消订单' : status === 'SH' ? '收货确认' : '开票确认';
      const message = status === 'QXS' || status === 'QX' || status === 'QXC' ? '是否确认取消订单交易' : status === 'SH' ? '是否确认收货' : '是否将订单修改为已收款';
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmSubmit(item, status);
      });
    },
    confirmSubmit(item, status) {
      const req = {
        orderId: item.id,
        status: status
      };
      changeOrderStatus(req).then(res => {
        if (res.errno === 0) {
          this.$notify.success({
            title: '成功',
            message: '提交成功'
          });
          if (this.customer === 'buyder') {
            this.handleOrderList();
          } else {
            this.sellderListOrder();
          }
        } else {
        }
        this.dialogVisible = false;
      });
    },
    // 触发分页器
    handleCurrentChange(val) {
      this.page.currentPage = val;
      if (this.customer === 'sellder') {
        this.sellderListOrder();
      } else {
        this.handleOrderList();
      }
    },
    handleOrderList() {
      listOrder({page: this.page.currentPage, size: this.page.pageSize}).then(res => {
        if (res.errno === 0) {
          this.page.total = res.data.count;
          this.tableData = res.data.orderVoList;
        }
      });
    },
    sellderListOrder() {
      sellderListOrder({page: this.page.currentPage, size: this.page.pageSize}).then(res => {
        if (res.errno === 0) {
          this.page.total = res.data.count;
          this.tableData = res.data.orderVoList;
        }
      });
    },
    jumpToOrderDetail(order) {
      this.$router.push({path: '/home/Mall/Paymented', query: {orderid: order.id, customer: this.customer, isHandleShow: true}});
    },
    //合同签署
    toContractDetail(item) {
      console.log(item);
      this.$router.push({
        path: '/User/ContractDetail',
        query: {
          id: null,
          tciId: item.contractId,
          orderNum: item.orderSn,
          customer: this.$route.query.customer
        }
      });
    }
  },
  mounted() {
    this.page.currentPage = 1;
    this.customer = this.$route.query.customer;
    if (this.customer === 'sellder') {
      this.$store.commit('changeMenuType', 'SELL');
      this.sellderListOrder();
    } else {
      this.$store.commit('changeMenuType', 'BUY');
      this.handleOrderList();
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .table {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .thead {
      background: #f5f5f5;
      color: #666;
      // font-weight: 600;
      // font-size: 12px;
      .tr {
        display: flex;

        .th {
          flex: 1;
          height: 45px;
          line-height: 45px;
          text-align: center;
        }
        .th:first-child {
          flex: 3;
        }
      }
    }
    .tbody {
      color: #333;

      .tbody-item {
        border: 1px solid #eeeeee;
        margin-top: 14px;

        .tbody-item-head {
          display: flex;
          // height: 36px;
          line-height: 36px;
          background: #f5f5f5;
          color: #909399;

          p {
            color: #7d7d7d;
            margin: 0 20px;

            span {
              color: #bbbbbb;
            }
          }
        }
        .tr {
          display: flex;

          .td {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 45px;
            // padding: 10px 0;
            box-sizing: border-box;
            border-left: 1px solid #eeeeee;

            &.status-box {
              .status {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                height: 70px;

                .order-details {
                  color: #333;
                  cursor: pointer;

                  &:hover {
                    color: #3a8ee6;
                  }
                }
                .finished {
                  color: #999;
                }
              }
            }
            &.goods-box {
              flex-direction: column;

              .goods {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 38px 0 18px;
                box-sizing: border-box;
                margin: 10px 0;

                .goods-info {
                  display: flex;

                  .image {
                    width: 80px;
                    height: 80px;

                    img {
                      width: 100%;
                    }
                  }
                  .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-left: 14px;

                    .name {
                      font-size: 16px;
                    }
                    .price {
                      font-size: 13px;
                      color: #999;
                    }
                  }
                }
              }
            }
            .button-box {
              text-align: center;

              .el-button + .el-button {
                margin-left: 0px;
                margin-top: 10px;
              }
            }
          }
          .td:first-child {
            flex: 3;
            border-left: 0;
          }
        }
      }
    }
  }
  .pager {
    margin: 10px 20px 50px 0;
    text-align: right;
  }
}
</style>
